import React, { useEffect, useState } from 'react'
import heroSectionImage from '../../../assets/icons/hero-section-image.svg'
import legendarycoachesImage from '../../../assets/icons/legendary-coaches.svg'
import SymbolTags from './SymbolTags'
import './styles/heroSection.less'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import { useSelector } from 'react-redux'
import { TokenApi } from '@/utils/api'
// import './index.less'


const HeroSection = () => {
  const [isNewUser, setIsNewUser] = useState(false)
  const auth = useSelector((state) => state.auth)

  const checkIsNew = async (email) => {
    const res = await TokenApi.get(`check/?user_id=${email}`)
    // console.log(res)
    setIsNewUser(!res?.exists)
  }

  useEffect(() => {
    if (auth.userInfo) {
      checkIsNew(auth.userInfo.data.profile.email)
    }
    // we have to check here is the user is first time logger or not by calling the api
    // and then change the state variable
  }, [auth])

  return (
    <div className="hero-section-container">
      <div className="left-container">
        <h1> Transform your writing with legendary coaches</h1>
        <p> Unlock your potential with coaching from literary giants like Hemingway, Tolstoy, Austen, and more, plus gamified writing.</p>
        <div>
          <SymbolTags symbol="💼" text="Get instant coaching" />
          <SymbolTags symbol="🚀" text="Personalised progress tracking" />
          <SymbolTags symbol="👨‍💻" text="Creative writing prompts"/>
        </div>

        <button>
          <Link to={isNewUser ? '/details' : '/library'}>Write a story</Link>
        </button>
      </div>

      <div className="right-container">
        <img src={legendarycoachesImage} alt="legendary-coaches image" />
      </div>
    </div>
  )
}

export default HeroSection
