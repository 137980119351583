import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './styles/template.less';

const Line = ({ isVisible, content, authorName, typingSpeed = 50 }) => {
  const [typedContent, setTypedContent] = useState('');

  useEffect(() => {
    if (!isVisible) {
      setTypedContent(''); 
      return;
    }
    let index = 0;
    const typeInterval = setInterval(() => {
      setTypedContent(prev => prev + content[index]);
      index += 1;
      if (index >= content.length) clearInterval(typeInterval);
    }, typingSpeed);

    return () => clearInterval(typeInterval);
  }, [isVisible, content, typingSpeed]);

  if (!isVisible) {
    return null; // Hide the entire line including authorName when not visible
  }
  return (
    <div className="line">
      <span className="persona">{authorName}</span>
      <span className="message">{typedContent}</span>
    </div>
  );
};

Line.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  content: PropTypes.string.isRequired,
  authorName: PropTypes.string.isRequired,
  typingSpeed: PropTypes.number, // Add typingSpeed prop
};

const Template = ({ authorMessage = '', authorName, isOnDisplayAuthor, isGreeting }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    if (!authorMessage) return;
    const lines = authorMessage.split('\n');
    const totalLines = lines.length;

    const showLines = async () => {
      for (let i = 0; i < totalLines; i++) {
        setCurrentIndex(i);
        setIsVisible(true);
        await new Promise(resolve => setTimeout(resolve, 6000)); // Display each line
        setIsVisible(false);
        await new Promise(resolve => setTimeout(resolve, 3000)); // Wait before next line
      }
      setCurrentIndex(totalLines);
      setIsVisible(false); // Ensure both message and author name are hidden
    };

    showLines();
  }, [authorMessage]);

  if (!authorMessage || currentIndex >= authorMessage.split('\n').length) {
    return null;
  }

  const lines = authorMessage.split('\n');
  const currentLine = lines[currentIndex] || '';
  const typingSpeed = currentLine.includes('...') ? 500 : 50; // Adjust speed based on content

  return (
    <div className="template-container">
      <div className="subtitle-container">
        <Line
          isVisible={isVisible}
          content={currentLine}
          authorName={isOnDisplayAuthor ? authorName : authorName}
          typingSpeed={typingSpeed} // Pass typingSpeed prop
        />
      </div>
    </div>
  );
};

Template.propTypes = {
  authorMessage: PropTypes.string,
  authorName: PropTypes.string,
  isOnDisplayAuthor: PropTypes.bool,
};

export default Template;

