import React, { useState, useEffect } from 'react';
import './slider.less';
import { useHistory } from 'react-router-dom';
import { TokenApi } from '@/utils/api';
import { useDispatch, useSelector } from 'react-redux';
import { Calendar, Modal, Select, Col, Row, Typography } from 'antd';
import moment from 'moment';
import Header from '@/layouts/header';
import AuthActions from '@/redux/auth';
import AuthorCard from '../components/AuthorCard'; // Import the new AuthorCard component
import { authors } from './authors';
import Toast from '../components/Toast';

const { Option } = Select;
const { Title } = Typography;

const Container = ({ children }) => {
  return <div className="wrapper">{children}</div>;
};

const TopicsButton = ({ text, setTopic, isFull }) => {
  const [isSelected, setIsSelected] = useState(false);

  useEffect(() => {
    setTopic((topics) => {
      if (topics.includes(text) && !isSelected) {
        return topics.filter((topic) => topic !== text);
      } else if (!topics.includes(text) && isSelected && !isFull) {
        return [...topics, text];
      }
      return topics;
    });
  }, [isSelected]);

  const selectTopic = () => {
    if (isSelected || !isFull) setIsSelected(!isSelected);
  };

  return (
    <div className={`topics-button ${isSelected ? 'selected' : ''}`} onClick={selectTopic}>
      {text}
    </div>
  );
};

const Personalize = ({ isStoreData, setIsDone, selectedAuthor }) => {
  const topics = [
    'Animals & Wildlife',
    'Fantasy & Magic',
    'Adventure & Exploration',
    'Mystery & Detective',
    'Superheroes & Villains',
    'Science & Technology',
    'History & Time Travel',
    'Sports & Athletics',
    'Friendship & Relationships',
    'Nature & Environment',
    'Space & Astronomy',
    'Art & Creativity',
    'Music & Dance',
    'Food & Cooking',
    'Humor & Funny Stories'
  ];



  const [selectedTopics, setSelectedTopics] = useState([]);
  const [date, setDate] = useState('');
  const [isFull, setIsFull] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const auth = useSelector((state) => state.auth);

  const sendData = async () => {
    console.log("sending data for onboarding , author info available is : ", selectedAuthor)
    const res = await TokenApi.post('onboard/', {
      user_id: auth?.userInfo.data.profile.email,
      dob: '', // currently dob is not used for onboarding ..
      interests: selectedTopics,
      selected_author: selectedAuthor.authorName,
      selected_author_description: selectedAuthor.authorDescription,

    });
    console.log(res);
  };

  const calenderOn = () => {
    setIsModalVisible(true);
  };

  useEffect(() => {
    if (isStoreData) sendData();
  }, [isStoreData]);

  useEffect(() => {
    if (selectedTopics.length >= 5 && !isFull) setIsFull(true);
    else if (selectedTopics.length < 5 && isFull) setIsFull(false);
  }, [selectedTopics]);

  useEffect(() => {
    if (isFull) {
      setIsDone(true);
    } else {
      setIsDone(false);
    }
  }, [isFull, date]);

  const onDateSelect = (date) => {
    setDate(date.format('YYYY-MM-DD'));
    setIsModalVisible(false);
  };

  const customHeaderRender = ({ value, onChange }) => {
    const start = 1990;
    const end = 2030;
    const year = value.year();
    const month = value.month();

    const monthOptions = [];
    const yearOptions = [];

    for (let i = 0; i < 12; i++) {
      monthOptions.push(
        <Option key={i} value={i}>
          {moment().month(i).format('MMMM')}
        </Option>
      );
    }

    for (let i = start; i <= end; i++) {
      yearOptions.push(
        <Option key={i} value={i}>
          {i}
        </Option>
      );
    }

    return (
      <div style={{ padding: 10 }}>
        <Row gutter={8}>
          <Col>
            <Title level={5}>Select Year and Month</Title>
          </Col>
        </Row>
        <Row gutter={8}>
          <Col>
            <Select
              value={year}
              style={{ width: 120 }}
              onChange={(newYear) => {
                const now = value.clone().year(newYear);
                onChange(now);
              }}
            >
              {yearOptions}
            </Select>
          </Col>
          <Col>
            <Select
              value={month}
              style={{ width: 150 }}
              onChange={(newMonth) => {
                const now = value.clone().month(newMonth);
                onChange(now);
              }}
            >
              {monthOptions}
            </Select>
          </Col>
        </Row>
      </div>
    );
  };

  return (
    <div className="personalize-container">
      <div className="first-section">
        <span>This helps us pick the right prompts for you!</span>
      </div>
      <div className="bottom-section">
        <p>
          Select your favorite topics:<span>({selectedTopics.length} / 5)</span>
        </p>
        <div className="tags-container">
          {topics.map((topic, index) => (
            <TopicsButton text={topic} key={index} setTopic={setSelectedTopics} isFull={isFull} />
          ))}
        </div>
      </div>
    </div>
  );
};

const Navigator = ({ index }) => {
  return (
    <div className="navigator-container">
      <div className="line-container">
        <div className="line">
          <div className="mover" style={{ width: `${(100 / 3) * index}%` }}></div>
        </div>
      </div>
      <div className="circles-container">
        <div className="circle" style={index >= 0 ? { backgroundColor: 'black', color: 'white' } : {}}>
          🔒
        </div>
        <div className="circle" style={index >= 1 ? { backgroundColor: 'black', color: 'white' } : {}}>
          👨‍🎨
        </div>
        <div className="circle" style={index >= 2 ? { backgroundColor: 'black', color: 'white' } : {}}>
          🚀
        </div>
        <div className="circle" style={index >= 3 ? { backgroundColor: 'black', color: 'white' } : {}}>
          🖊️
        </div>
      </div>
    </div>
  );
};

const LastTab = () => {
  return (
    <div className="lasttab-container">
      <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 64 64">
        <path d="M32,6C17.641,6,6,17.641,6,32c0,14.359,11.641,26,26,26s26-11.641,26-26C58,17.641,46.359,6,32,6z M29,42L18,31l2-3l9,6 l13.957-12L46,25L29,42z"></path>
      </svg>
      <p>Welcome to applyin.co</p>
    </div>
  );
};

const Slider = () => {
  const [tabIndex, setTabIndex] = useState(1);
  const [isStoreData, setIsStoreData] = useState(false);
  const [isDone, setIsDone] = useState(false);
  const [authorInfo, setAuthorInfo] = useState({});
  // current Author = 0 means no author has been selected yet
  const [currentAuthor, setCurrentAuthor] = useState(0);
  const [isAuthorToastOn, setIsAuthorToastOn] = useState(false)

  const history = useHistory();
  const dispatch = useDispatch();

  const changeTab = async () => {
    console.log("we are trying to change the tab : ", tabIndex)
    // console.log("done or not : ", isDone)
    //if (!isDone) return;


    if (tabIndex === 1) {
      const infoLength = Object.entries(authorInfo).length
      if (infoLength === 0) {
        // we cannot move forward until the author is set
        console.log("cannot move forward .. , need to select author")
        // we will show toast message here ...
        setIsAuthorToastOn(true)
        // showing toast for 1.5 seconds ..
        setTimeout(() => { setIsAuthorToastOn(false) }, 1500)
        // toast message here ...

        return;
      }
      setTabIndex(tabIndex + 1);
    } else if (tabIndex === 2) {
      console.log("done or not : ", isDone)
      if (!isDone) {
        console.log("have not selected 5 topics of interests ..")
        return
      }
      setIsStoreData(true);
      setTabIndex(tabIndex + 1);
    } else if (tabIndex === 3) {
      await dispatch(AuthActions.getCurrentUser());
      history.push('/library');
    }
  };



  return (
    <>
      <Toast text={'Select Writing Coach To Proceed'} isOn={isAuthorToastOn} />
      <div className="spreader">

        <div className="slider-container">


          <div className="header">
            <Header />
          </div>


          <Navigator index={tabIndex} />
          <div className="container-list">
            <div style={{ transform: `translateX(-${100 * tabIndex}%)` }}>
              <Container></Container>

              <Container>
                <div className='author-heading-wrapper'>
                  <h4 className="main-heading">Choose your writing coach</h4>
                  <h5 className="sub-heading">Select from our roster of legendary writers</h5>
                </div>
                <div className="author-cards-grid">

                  {authors.map((author, index) => (
                    <AuthorCard
                      key={author.id}
                      authorPicture={author.authorPicture}
                      authorName={author.authorName}
                      authorDescription={author.authorDescription}
                      selectAuthor={() => {
                        console.log("currently selected the author : ", author.id)
                        setAuthorInfo(author)
                        setCurrentAuthor(author.id)
                      }}
                      currentlySelected={author.id === currentAuthor ? true : false}
                    />
                  ))}
                </div>
              </Container>

              <Container>
                <Personalize isStoreData={isStoreData} setIsDone={setIsDone} selectedAuthor={authorInfo} />
              </Container>
              <Container>
                <LastTab />
              </Container>
            </div>
          </div>
          <div className="buttons">
            <button onClick={changeTab}>{tabIndex === 3 ? "Let's write" : 'Next'}</button>
          </div>
        </div>
      </div>

    </>

  );
};

export default Slider;
