import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Button, Card, Col, Input, Row, Form, Typography, message, Space } from 'antd'
// import { Button, Card, Col, Input, Row, Form, Typography, message, Space } from 'antd'
import AuthActions from '@/redux/auth'
import { NavLink } from 'react-router-dom'
import { GoogleLogin } from '@leecheuk/react-google-login'
import { GoogleOutlined, LinkOutlined, LinkedinOutlined, LinkedinFilled } from '@ant-design/icons'
import { LinkedIn } from 'react-linkedin-login-oauth2'
import { Api } from '@/utils/api'
import { Helmet } from 'react-helmet'
// import { Helmet } from 'react-helmet'

const googleClientId = process.env.GOOGLE_CLIENT_ID

const Login = ({ history }) => {
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(null)

  const onFinish = async (values) => {
    setLoading('default')
    try {
      await dispatch(
        AuthActions.login({
          username: values.email,
          password: values.password
        })
      )

      await dispatch(AuthActions.getCurrentUser())
      setLoading(null)
    } catch (err) {
      message.error('Incorrect credentials provided!')
      setLoading(null)
    }
  }

  const responseGoogle = (authResult) => {
    if (authResult && authResult.credential) {
      setLoading('google')
      Api.post('google/login/', {
        token: authResult.credential
      })
        .then(async (res) => {
          const { access_token, refresh_token } = res
          if (access_token && refresh_token) {
            window.localStorage.setItem('accessToken', access_token)
            window.localStorage.setItem('refreshToken', refresh_token)
            await dispatch(AuthActions.getCurrentUser())
          } else {
            message.error('Something went wrong.')
          }
          setLoading(null)
        })
        .catch((err) => {
          message.error('Wrong google token')
          setLoading(null)
        })
    }
  }

  const handleSuccess = (e) => {
    if (e) {
      setLoading('linkedin')
      Api.post('linkedin/login/', {
        token: e
      })
        .then(async (res) => {
          const { access_token, refresh_token } = res
          if (access_token && refresh_token) {
            window.localStorage.setItem('accessToken', access_token)
            window.localStorage.setItem('refreshToken', refresh_token)
            await dispatch(AuthActions.getCurrentUser())
          } else {
            message.error('Something went wrong.')
          }
          setLoading(null)
        })
        .catch((err) => {
          message.error('Wrong google token')
          setLoading(null)
        })
    }
  }

  const handleFailure = (e) => {
    message.error(e)
  }

  useEffect(() => {
    setTimeout(() => {
      google.accounts.id.initialize({
        //client_id: process.env.GOOGLE_CLIENT_ID,
        client_id: "966161301324-brf0g8ur114q70rrh34m7t8uhkudd5vi.apps.googleusercontent.com",
        callback: responseGoogle
      })
      google.accounts.id.renderButton(document.getElementById('googleLogin'), {
        theme: 'outline',
        size: 'large',
        width: 300
      })
      google.accounts.id.prompt()
    }, 500)
  }, [])

  return (
    <div className="inner-wrapper">
      <Row justify="center" style={{ width: '100%' }}>
        <Helmet>
          <title>Login to ApplyIn: Access Your AI-Driven Interview Practice Sessions</title>
          <meta
            name="description"
            content="Log in to ApplyIn to immerse yourself in AI-powered mock interview experiences. Leverage ChatGPT technology for unparalleled interview preparation and insights."
          />
        </Helmet>
        <Col span={24} md={20} lg={15} xl={10}>
          <Typography.Title level={2} align="center">
            Sign In
          </Typography.Title>
          <Form form={form} name="Login" layout="vertical" onFinish={onFinish}>
            {/* <Form.Item
              name="email"
              label={'Email Address'}
              required={false}
              rules={[
                { required: true, message: 'Email is required' },
                { type: 'email', message: 'Invalid email' }
              ]}
            >
              <Input placeholder="Email" size="large" />
            </Form.Item>

            <Form.Item
              name="password"
              label={'Password'}
              required={false}
              rules={[{ required: true, message: 'Password is required' }]}
            >
              <Input.Password placeholder="Password" size="large" />
            </Form.Item>

            <Row justify="center" style={{ marginTop: 100 }}>
              <Button
                htmlType="submit"
                type="primary"
                block
                loading={loading === 'default'}
                size="large"
                style={{ maxWidth: 390 }}
              >
                LOG IN
              </Button>
            </Row> */}
            <Row justify="center" style={{ marginTop: 50 }}>
              {/* <GoogleLogin
                clientId={googleClientId}
                render={(renderProps) => (
                  <Button
                    icon={<GoogleOutlined style={{ fontSize: '35px' }} />}
                    loading={loading === 'google'}
                    htmlType="button"
                    type="ghost"
                    block
                    onClick={renderProps.onClick}
                    disabled={renderProps.disabled}
                    size="large"
                    style={{ maxWidth: 390, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                  >
                    Log in with Google
                  </Button>
                )}
                buttonText="Login"
                onSuccess={responseGoogle}
                onFailure={responseGoogle}
                cookiePolicy={'single_host_origin'}
              /> */}

              <div id="googleLogin"></div>
            </Row>
            {/* <Row justify={'center'} style={{ marginTop: 20 }}>
              <p>OR</p>
            </Row>
            <Row justify="center">
              <LinkedIn
                clientId={process.env.LINKEDIN_CLIENT_ID}
                redirectUri={`${window.location.origin}/linkedin`}
                onSuccess={handleSuccess}
                onFailure={handleFailure}
                scope="r_liteprofile r_emailaddress w_member_social"
                className="linkedin"
              >
                {({ linkedInLogin }) => (
                  <Button
                    loading={loading === 'linkedin'}
                    htmlType="button"
                    type="ghost"
                    block
                    size="middle"
                    onClick={linkedInLogin}
                    style={{
                      maxWidth: 300,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      height: 44
                    }}
                  >
                    <div
                      style={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}
                    >
                      <LinkedinFilled style={{ fontSize: '20px' }} />
                      <span style={{ flex: 1, fontSize: 15, fontWeight: 500 }}>Log in with LinkedIn</span>
                    </div>
                  </Button>
                )}
              </LinkedIn>
            </Row> */}

            <Row justify="center" style={{ marginTop: 30 }}>
              Don't have an account? &nbsp;&nbsp;&nbsp;{' '}
              <NavLink to="/signup">
                <u>Sign Up</u>
              </NavLink>
            </Row>
          </Form>
        </Col>
      </Row>
    </div>
  )
}

export default Login
