import React from 'react'
import { useEffect } from 'react'
import logo from '../../../assets/images/logo.svg'
import { Row, Typography } from 'antd'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import Col from 'antd/es/grid/col'
import icon from '../../../assets/icons/copy.png'
import { Avatar } from 'antd'
import { UserOutlined } from '@ant-design/icons'
import { useState } from 'react'
import Header from '@/layouts/header'
import { TokenApi } from '@/utils/api'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { Button } from 'antd'
import './stories.less'
import { EditIcon, ViewIcon } from '@/icon'
import { Helmet } from 'react-helmet'
import achievementImage from '../../../assets/icons/achievement.svg'

const { Text } = Typography

const Elem = ({ dateStr, name, redirect, storyId, history, feedbackScore }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const formatString = (str) => {
    let result = str.split(':')[0].trim()
    return result
  }

  function formatDate(dateString) {
    const givenDate = new Date(dateString)

    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    ]

    const month = months[givenDate.getMonth()]
    const day = givenDate.getDate()
    const year = givenDate.getFullYear()
    let hours = givenDate.getHours()
    const minutes = givenDate.getMinutes()
    const ampm = hours >= 12 ? 'PM' : 'AM'
    hours = hours % 12
    hours = hours ? hours : 12 // Handle midnight (0 hours)

    return `${month} ${day} ${year} ${hours}:${minutes < 10 ? '0' + minutes : minutes} ${ampm}`
  }

  return (
    <>
      <Helmet>
        <title>applyin.co: Library</title>
        <meta name="description" content="Your written story" />
      </Helmet>

      <div id="element" className="stories-list">
        <div id="elem_left" onClick={() => history.push(`/read/${storyId}`)}>
          <img src={icon} alt="icon" style={{ height: '60%', width: '20px', marginRight: '10px' }} />
          {/* <div id="elem_score">{feedbackScore ? feedbackScore : '?'}</div> */}
          <span id="elem_left_title">{windowWidth >= 1024 ? name : formatString(name)}</span>
        </div>
        <div id="elem_right">
          <span id="elem_right_avt">{formatDate(dateStr)}</span>
          {/* <Avatar icon={<UserOutlined />} /> */}
          <span onClick={() => redirect(storyId)} className="stories-action">
            <EditIcon />
          </span>
          {/* <span className="stories-action" onClick={() => history.push(`/read/${storyId}/`)}>
          <ViewIcon />
        </span> */}
        </div>
      </div>
    </>
  )
}
Elem.propTypes = {
  dateStr: PropTypes.any,
  name: PropTypes.any,
  redirect: PropTypes.any,
  storyId: PropTypes.any,
  history: PropTypes.any
}

const Stories = () => {
  const [stories, setStories] = useState([]);
  const history = useHistory();
  const [totalClaps, setTotalClaps] = useState(0);

  const fetchList = async () => {
    const res = await TokenApi.get('story-activity-user-stories/');
    console.log('data', res);
    setStories(res);

    let total_clap = 0;
    res.forEach((story) => {
      total_clap += story.applause_count;
    });

    setTotalClaps(total_clap);
  };

  useEffect(() => {
    fetchList();
  }, []);

  const redirect = (story_id) => {
    console.log(`Redirecting to /write?storyId=${story_id}`);
    history.push(`/write?storyId=${story_id}`);
  };

  const newStory = () => {
    localStorage.removeItem('story_id');
    console.log('Creating new story, redirecting to /write');
    history.push('/write');
  };

  return (
    <>
      <Helmet>
        <title>applyin.co: Library</title>
        <meta name="description" content="Your written story" />
      </Helmet>
      <div className="main" style={{ height: '100vh', width: '100vw' }}>
        <div className="header" style={{ height: '68px' }}>
          <Header />
        </div>

        {totalClaps !== 0 && (
          <div className="achievement">
            <div className="achievement-left">
              <img src={achievementImage} alt="achievement icon" />
            </div>
            <div className="achievement-right">
              <h2>Congratulations</h2>
              <p>Your stories have received {totalClaps} kudos! 👏</p>
            </div>
          </div>
        )}

        <Col id="stories-container">
          <div
            className=""
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: '10px'
            }}
          >
            <Text strong type="primary" className="welcome-name-text" style={{ fontSize: 25 }}>
              Stories
            </Text>

            <Button type="primary" size="medium" className="new-story-button" onClick={() => history.push('/catalog')}>
              New Story
            </Button>
          </div>

          {stories.length === 0 ? (
            <div id="stories-list" className="body">
              <p id="empty" style={{ textAlign: 'center' }}>
                📚 Your library is empty! Click on “New story” to start writing 📝
              </p>
            </div>
          ) : (
            <div id="stories-lists" className="body">
              {stories.map((story, index) => (
                <div className="" key={index}>
                  <Elem
                    dateStr={story.timestamp}
                    name={story.title}
                    storyId={story.story_id}
                    redirect={redirect}
                    history={history}
                    feedbackScore={story.feedback}
                  />
                </div>
              ))}
            </div>
          )}
        </Col>
      </div>
    </>
  );
};

export default Stories;
